import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Privacy = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata?.title || `FMCorreia`



  return (
    <Layout location={location} title={siteTitle}>
        <Seo title="Privacy Policy" />
        <section className="privacy-policy">
            <div className="first-section global-wrapper">
                <h1>Privacy Policy</h1>
                 <h2>Definitions:</h2>
            <p><strong>FMCorreia network (or &quot;FMCorreia websites&quot; or &ldquo;these websites&rdquo;</strong>): Includes all websites that use the domain <a href="http://fmcorreia.com/">fmcorreia.com</a>. It can also refer to the content hosted on these websites.</p>
            <p><strong>Users</strong>: A person that interacts with the FMCorreia websites or content. Users can either be registered or not.</p>
            <p><strong>You</strong>: Refers to you, a user of the FMCorreia Network.</p>
            <hr/>
            <p>This document contains the privacy policy for the FMCorreia websites and all the content under the <a href="http://fmcorreia.com/">fmcorreia.com</a> domain. It also details the use of your data by services required to make these websites work.</p>
            <p>These websites are developed and managed by Filipe Correia (about).</p>
            <p><br/></p>
            <h3>What data is collected</h3>
            <p>FMCorreia websites collect minimal information about you to allow their correct functioning. This data may include your IP address and other statistical data.</p>
            <p>If you are a registered user of any service under the FMCorreia websites, your name, email and approximate location are stored for safety reasons. Your identity is never associated with analytical data.</p>
            <p><br/></p>
            <h3>Cookies</h3>
            <p>The FMCorreia Network uses cookies. Some cookies are required to make these websites work.</p>
            <p>You can restrict third-party cookies through your browser&apos;s settings.</p>
            <h3>How your data is used</h3>
            <p>Some collected data is used for analytical purposes. This data never includes personal identifiers. The FMCorreia network uses Google Analytics and Cloudflare Web Analytics to understand how you use these websites and to improve your experience.</p>
            <p>These services&apos; terms of use and privacy policy apply. It&apos;s these companies&apos; responsibility to comply with their policies.</p>
            <p>In case you are a registered user, your data is stored on the Auth0 database located in the European Union. Your login data and data associated with your registration is used solely for its purpose. This data will never be sold or shared with third parties.</p>
            <p>The data you send on forms is sent to a database located in the European Union and used for its sole purpose.</p>
            <p>Data collected and stored by FMCorreia websites will never be shared with third parties unless some third party delivers a service required for these websites to work. FMCorreia only uses services from companies that promise to protect your data and that follow the GDPR.</p>
            <p><br/></p>
            <h3>Storage of your data</h3>
            <p>Data that you voluntarily send (ex: through forms) is stored for two years. After that period, data will either get deleted or anonymized.</p>
            <p>Your login data is never deleted unless you require the deletion of all your data.</p>
            <p>Emails sent to any address at <a href="http://fmcorreia.com">fmcorreia.com</a> are stored for a minimal duration of three years.</p>
            <p><br/></p>
            <h3>Protection of your data</h3>
            <p>The FMCorreia Network uses numerous techniques to guarantee the safety of your data, including the use of a Firewall and the protection of our databases.</p>
            <p>Despite these measures, it&apos;s not possible to guarantee full protection. For this matter, you should also implement digital hygiene, by using different passwords on different websites and by not sharing login data with anyone.</p>
            <p><br/></p>
            <h3>Your rights</h3>
            <p>You have the right to ask:</p>
            <ul>
                <li>To receive a summary of all the data that was collected by FMCorreia websites about you.</li>
                <li>For the deletion of your data, if you don&apos;t want your data to be treated under this privacy policy.</li>
            </ul>
            <p>You may exercise these rights by contacting hello@fmcorreia.</p>
            <p>You must keep your personal information updated at all times.</p>
            <p><br/></p>
            <h3>Automated decisions</h3>
            <p>FMCorreia services do not rely on automated decisions.</p>
            <p><br/></p>
            <h3>Final notes</h3>
            <p>In case of changes to this privacy policy, you shall be informed.</p>
            <p><br/></p>
            <h1>Terms of Use</h1>
            <p>This document regulates your use of the content stored at the FMCorreia network.</p>
            <p>The FMCorreia websites are for personal use. You may not reproduce, distribute, republish, mirror, download, display, exchange, modify, sell or transmit any materials you copy from these Websites, including but not limited to any software, text, images, audio and video.</p>
            <h3>Copyright</h3>
            <p>All content under the domain <a href="http://fmcorreia.com">fmcorreia.com</a>, including images, text, videos, audio, and graphics, is copyrighted and protected by copyright laws. All rights are reserved to Filipe Correia (&copy; FMCorreia - Filipe Correia). The rights of some content on certain websites inside the FMCorreia network may be of other people.</p>
            <p>It&apos;s forbidden the distribution or use of this content outside of the FMCorreia websites.</p>
            <p>You can ask Filipe Correia to use some of his content by contacting him at <a href="mailto:hello@fmcorreia.com">hello@fmcorreia.com</a>. You must inform what content you will use, where you will use or distribute it and other relevant details. You must receive confirmation from Filipe Correia. You must comply with the terms subject to your use, which may include the mandatory inclusion of the following copyright notice near the content: (c) FMCorreia - Filipe Correia. Information about these terms will be sent to you with the approval for your use.</p>
            <p>Content that was not produced by Filipe Correia that is included on the FMCorreia websites will have information about its copyright holders. This content may be protected by different regulations.</p>
            <p>The violation of copyright laws is a crime.</p>
            <p><br/></p>
            <p>These documents may be updated in the near future.</p>
          </div>
        </section>

    </Layout>
  )
}

export default Privacy

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
